import 'intersection-observer';

import React from 'react';

import Section from './Section';
import Item from './Item';

function Growth() {
  return (
    <div className="App">
      <Section>
        <Item
          id="princeton"
          description="Over the past 12 years, we have built our firm to 95+ employees, across 7 global office locations, and partner with 55+ large and midsize pharma, biopharma, and MedTech companies per year. We have relationships with 12 of the top 15 global life sciences companies. More than 60% of our work is global, including western and eastern Europe, Latin America, Canada, Asia Pacific, and the Middle East."
          seg={[0, 64]}
        />
        <Item
          id="new_york"
          description="As a company, we execute 300+ engagements per year across a wide breadth of therapeutic areas and a variety of stakeholders."
          seg={[68, 127]}
        />
        <Item
          id="london"
          description="Our firm’s success and growth has been driven organically, without a business development team or department. Our best business development tool is our impact through partnership and the quality of our next engagement."
          seg={[139, 184]}
        />
        <Item
          id="san_francisco"
          description="Our firm has been growing each year since inception across all key metrics, including employees (15 new consulting team members in 2020), geographies (7 offices in total), and clients/revenues."
          seg={[200, 255]}
        />
      </Section>
    </div>
  );
}

export default Growth;

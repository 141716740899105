/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import Yellowkey from '../../../static/assets/yellow_key.svg';
import * as globalAnimation from '../../animations/globalAnimation';

import './Item.scss';

export default class Item extends Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    onChange: PropTypes.func,
  };

  changeItem = inView => {
    const { onChange } = this.props;
    const tl = new TimelineLite({ force3D: !0, yoyo: true });

    if (inView) {
      onChange();
      globalAnimation.animateLineToLeft(tl, '.svg-line__line_yellow', '.svg_circle_yellow');
    }
  };

  render() {
    const { description, id } = this.props;

    return (
      <div className="item" id={id}>
        <div className="item__inner">
          <Observer tag="div" onChange={this.changeItem}>
            <div className="line line-horizontal">
              <Yellowkey />
              <p className="item__description">{description}</p>
            </div>
          </Observer>
        </div>
      </div>
    );
  }
}

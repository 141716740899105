/* eslint-disable */
import React, { Component } from 'react';
import Lottie from 'lottie-web';
import PropTypes from 'prop-types';
import { TimelineLite, TweenLite } from 'gsap';
import _debounce from 'lodash/debounce';
import classnames from 'classnames';
import './Section.scss';
import animationData from '../../animations/updated_map_animation.json';
import downArrow from '../../../static/img/home-page/down_arrow.svg';
//line below reolves the issue of importing scrollToPlugin in production
const ScrollToPlugin = require('gsap/ScrollToPlugin');

export default class Section extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    activeItem: this.props.children[0].props.id,
    currentSeg: [this.props.children[0].props.seg],
    isPaused: true,
  };

  scrollUp = undefined;
  isScrolling = false;
  isAnimating = false;

  section = React.createRef();

  componentDidMount() {
    this.anim = Lottie.loadAnimation({
      container: document.getElementById('animation'),
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        // line below is to fix the resizing issue in chrome browser
        className: 'map-anim-container',
      },
    });
    this.anim.setSpeed(0.75);
    window.addEventListener('mousewheel', this.onMouseWheel);
    window.addEventListener('scroll', (this.scrollRef = _debounce(this.onScroll, 1)));
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimer);

    window.removeEventListener('mousewheel', this.onMouseWheel);
    window.removeEventListener('scroll', this.scrollRef);
  }

  // Find out scroll direction
  onMouseWheel = ({ deltaY }) => {
    this.scrollUp = deltaY < -0.2;
  };

  // On scroll function handles item position
  onScroll = () => {
    clearTimeout(this.scrollTimer);

    this.scrollTimer = setTimeout(() => {
      this.positionItem();
    }, 1);
  };

  // Positioning the item content
  positionItem = () => {
    const section = this.section.current;
    const child = section.querySelector(`#${this.state.activeItem}`);
    const scrollTop = window.pageYOffset || window.scrollY;
    const { top, bottom } = section.getBoundingClientRect();
    const childTop = child.getBoundingClientRect().top;
    const scrollTo = Math.round(childTop + scrollTop);
    const threshold = 1;

    // Not positioning if not active child or is scrolling
    if (!child || this.isScrolling) {
      return;
    }

    // Not positioning first item until scrolled 1/3 of screen height
    if (top > threshold) {
      return;
    }

    // Not positioning when scrolled through last item
    if (bottom + threshold < window.innerHeight) {
      return;
    }

    clearTimeout(this.scrollTimer);

    this.isScrolling = true;

    const t = new TimelineLite();

    // Positioning the item content with gsap scroll to plugin
    t.to(window, 0.8, {
      scrollTo: scrollTo,
      ease: 'Power4.easeInOut',
    }).call(() => {
      this.isScrolling = false;
    });
  };

  // Sets active item and animates the right image in
  onItemChange = (id, seg) => {
    this.setState({ activeItem: id, currentSeg: seg, isPaused: false });

    this.anim.playSegments(seg, true);
  };

  // Scroll to element used in navigation
  scrollToElement = id => {
    TweenLite.to(window, 1, {
      ease: 'Power4.easeInOut',
      scrollTo: id,
    });
  };

  // Render Buttons
  renderNavigationItem = item => (
    <button
      key={`button-${item.props.id}`}
      className={classnames('section__button', {
        isActive: item.props.id === this.state.activeItem,
      })}
      onClick={() => this.scrollToElement(`#${item.props.id}`)}
    >
      {item.props.title}
    </button>
  );

  // Render Images
  renderImages = item => (
    <div
      key={`image-${item.props.id}`}
      className="section__image"
      ref={c => {
        this.images[item.props.id] = c;
      }}
    >
      <img src={item.props.image} className="section__imageSource" alt="" />
    </div>
  );

  render() {
    const { children } = this.props;
    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <section className="section" ref={this.section}>
        <section className="section__container" id="scroll-container">
          <div className="section__row position-relative">
            <div className="section__navigation">
              <div className="section__buttons">
                <div className="position-absolute verticle-text-wrapper">
                  <div id="verticle-text" className="text-white text-capitalize verticle-text">
                    <h6 className="d-flex align-items-center justify-content-center">
                      scroll to explore{' '}
                      <span>
                        <img src={downArrow} alt="down-arrow" className="p-2 rotate-arrow" />
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="section__colLeft">
              <div className="section__imageOffset">
                <div className="section__images">
                  <div id="animation" />
                </div>
              </div>
            </div>
            <div className="section__colRight">
              {children.map(child =>
                React.cloneElement(child, {
                  key: child.props.id,
                  onChange: () => this.onItemChange(child.props.id, child.props.seg),
                })
              )}
            </div>
          </div>
        </section>
      </section>
    );
  }
}

/*eslint-disable*/

import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';
import Growth from '../components/GrowthComponents';
import { TweenLite, TimelineLite, Power1 } from 'gsap';
import { InView } from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Yellowkey from '../../static/assets/yellow_key.svg';
import GrowthMap from '../../static/assets/growth_map.svg';
import DescriptionWithKey from '../components/DescriptionWithKey';
import * as globalAnimation from '../animations/globalAnimation';
import ReadMore from '../../static/assets/Readmore.svg';
import animateDescription from '../animations/OurStory';
import mapResp from '../../static/img/our-story/map_resp.png';

class OurStory extends React.Component {
  constructor(props) {
    super(props);

    // reference to the DOM node
    this.h2 = null;
    this.box = null;
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.animateImage(
      this.tl,
      '.svg-line__line_yellow',
      '.svg_circle_yellow',
      '.bprg-image',
      '.graphic_wbg'
    );
  }

  // animateLine(classId) {
  //   let o = 1.3;
  //   this.tl
  //     .set('.line-horizontal', { x: 0 })
  //     .staggerFromTo(
  //       classId,
  //       0.5,
  //       { attr: { width: 0 } },
  //       { attr: { width: 88 }, clearProps: 'all' }
  //     )
  //     .staggerFromTo(
  //       '.svg_circ',
  //       0.5,
  //       { strokeDasharray: 16, strokeDashoffset: 16 },
  //       { strokeDashoffset: 0, clearProps: 'all' }
  //     )
  //     .staggerFromTo('.description', 0.3, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, 0.3)
  //     .to(
  //       '.line-horizontal',
  //       0.5,
  //       { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
  //       1
  //     )
  //     .staggerFrom(
  //       '#desc_one',
  //       o,
  //       {
  //         opacity: 0,
  //         y: 40,
  //         ease: Power1.easeOut,
  //       },
  //       0,
  //       o
  //     )
  //     .staggerFrom(
  //       '#desc_two',
  //       o,
  //       {
  //         opacity: 0,
  //         y: 40,
  //         ease: Power1.easeOut,
  //       },
  //       0,
  //       o
  //     )
  //     .staggerFrom(
  //       '.graphic_wbg',
  //       1.3,
  //       {
  //         x: 120,
  //         y: 30,
  //         opacity: 0,
  //         ease: Power1.easeOut,
  //       },
  //       0,
  //       1
  //     )
  //     .staggerTo(
  //       '.bprg-image',
  //       1.3,
  //       {
  //         x: '-70px',
  //         y: '-70px',
  //         opacity: 1,
  //         ease: Power1.easeOut,
  //       },
  //       0,
  //       1
  //     );
  // }
  // animateHeading(element1, element2) {
  //   let o = 0.5;
  //   this.tl
  //     .staggerFrom(
  //       element1,
  //       o,
  //       {
  //         opacity: 0,
  //         y: 40,
  //         ease: Power1.easeOut,
  //       },
  //       0,
  //       o
  //     )
  //     .staggerFrom(
  //       element2,
  //       o,
  //       {
  //         opacity: 0,
  //         y: 40,
  //         ease: Power1.easeOut,
  //       },
  //       0,
  //       o
  //     );
  // }

  renderNumber = () => {
    const {
      data: {
        markdownRemark: {
          frontmatter: { ourStory },
        },
      },
    } = this.props;
    ourStory.map((story, index) => {
      let tal = document.getElementById(`counter_${index}`);
      let counter = { [`counter_${index}`]: 0 };
      TweenLite.to(counter, 2.5, {
        [`counter_${index}`]: story.number,
        onUpdate: function() {
          tal.innerHTML = numberWithCommas(
            Math.ceil(counter[`counter_${index}`]),
            index === 2 ? '%' : ''
          );
        },
        ease: Circ.easeOut,
      });
    });

    function numberWithCommas(x, sufix) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sufix;
    }
  };

  descriptionAnimation = inView => {
    if (inView) {
      animateDescription(this.tl, '.svg-line__line_blue', '.svg_circle_blue', '.story_description');
    }
  };

  scrollAnimations = inView => {
    if (inView) {
      this.renderNumber();
    }
  };
  render() {
    const {
      ourStory,
      image,
      rowDescriptionOne,
      rowDescriptionTwo,
      rowDescriptionThree,
      rowDescriptionFour,
      growthDescription,
    } = this.props.data.markdownRemark.frontmatter;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Our Story" />
        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper">
                <Row>
                  <Col>
                    <div className="wrapper">
                      <div className="page_heading_section text-center">
                        <h3 className="page_heading text-uppercase d-lg-block d-none title">
                          Our Story
                        </h3>
                        <h4 className="text-uppercase page_heading_child subtitle">Our Story</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="bg-white">
                  <Col xs={12} lg={6}>
                    <div className="wrapper">
                      <Row>
                        <Col xs={12}>
                          <div className="position-relative line line-horizontal">
                            <Yellowkey />
                          </div>
                          <p id="desc_one">{rowDescriptionOne}</p>
                          <p id="desc_two">{rowDescriptionTwo}</p>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xs={12} lg={6}>
                    <div className="d-lg-none">
                      <div className="ourstory_imgbox">
                        <Img className="m-lg-0 mx-auto" fluid={image.childImageSharp.fluid} />
                      </div>
                    </div>
                    <div className="p-4 d-none d-lg-block">
                      <div className="ourstory_imgbox graphic_wbg">
                        <div className="bg-orange">
                          <div className="">
                            <Img
                              className="img-fluid bprg-image cover_pic"
                              fluid={image.childImageSharp.fluid}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mt-3">
                <Row className="bg-white py-4 description_section">
                  <Col xs={12} lg={6}>
                    <div className="wrapper">
                      <Container>
                        <InView
                          onChange={this.scrollAnimations}
                          root={null}
                          rootMargin="0px"
                          threshold="0.5"
                          triggerOnce
                        >
                          {({ inView, entry, ref }) => (
                            <div ref={ref}>
                              <Row>
                                {ourStory.map((story, index) => (
                                  <Col xs={6} lg={6} key={String(index)}>
                                    <div className="p-0 p-lg-4">
                                      <h2
                                        className="counter text-lightblue text-bold"
                                        id={`counter_${index}`}
                                      >
                                        {index === 2 ? '%' : null}
                                      </h2>
                                      <p className="text-medium">{story.title}</p>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          )}
                        </InView>
                      </Container>
                    </div>
                  </Col>
                  <Col xs={12} lg={6}>
                    <div className="description-wrapper">
                      <InView
                        onChange={this.descriptionAnimation}
                        root={null}
                        rootMargin="0px"
                        threshold="0"
                        triggerOnce
                      >
                        {({ inView, entry, ref }) => (
                          <div ref={ref}>
                            <Container>
                              <Row>
                                <Col>
                                  <DescriptionWithKey
                                    description={rowDescriptionThree}
                                    className="key_description"
                                    id="key_one"
                                  />
                                  <DescriptionWithKey
                                    description={rowDescriptionFour}
                                    className="key_description"
                                    id="key_two"
                                  />
                                  <div className="d-flex justify-content-center justify-content-md-start story_description">
                                    <div className="py-3 fade-up-btn">
                                      <Link
                                        to="/client-testimonials/"
                                        id="desc"
                                        className="learn_more bordered-btn text-center"
                                      >
                                        What our clients are saying{' '}
                                        <ReadMore
                                          className="arrow-translate mb-0"
                                          height="10px"
                                          width="40px"
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        )}
                      </InView>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={12}>
              <Row className="bg-appcolor">
                <Col>
                  <div className="center">
                    <section className="growth_section">
                      <Row>
                        <Col>
                          <div className="wrapper">
                            <div className="page_heading_section text-center">
                              <h3 className="page_heading text-uppercase d-lg-block d-none white title">
                                Our Growth
                              </h3>
                              <h4 className="text-uppercase page_heading_child text-white subtitle ">
                                Our Growth
                              </h4>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-none d-lg-block">
                        <Col>
                          <Growth />
                        </Col>
                      </Row>
                      <Row className="d-lg-none d-block">
                        <Col>
                          <img src={mapResp} className="img-fluid" alt="map-image" />
                        </Col>
                        <Col xs={12} lg={6}>
                          <div className="wrapper">
                            <Row>
                              <Col xs={12}>
                                <div className="position-relative line line-horizontal">
                                  <Yellowkey />
                                </div>
                                <p className="text-white">
                                  Over the past 11 years, we have built our firm to 90+ employees,
                                  across 6 global office locations, and partner with 50 large,
                                  midsize, and early stage biopharmaceutical clients per year. We
                                  have relationships with 10 of the top 15 global pharmaceutical
                                  companies. More than 60% of our work is global, including western
                                  and eastern Europe, Latin America, Canada, Asia Pacific, and the
                                  Middle East.
                                </p>
                              </Col>
                              <Col xs={12}>
                                <div className="position-relative line line-horizontal">
                                  <Yellowkey />
                                </div>
                                <p className="text-white">
                                  As a company, we execute 275+ engagements per year across a wide
                                  breadth of therapeutic areas and a variety of stakeholders.
                                </p>
                              </Col>
                              <Col xs={12}>
                                <div className="position-relative line line-horizontal">
                                  <Yellowkey />
                                </div>
                                <p className="text-white">
                                  Our firm’s success and growth has been driven organically, without
                                  a business development team or department. Our best business
                                  development tool is our impact through partnership and the quality
                                  of our next engagement.
                                </p>
                              </Col>
                              <Col xs={12}>
                                <div className="position-relative line line-horizontal">
                                  <Yellowkey />
                                </div>
                                <p className="text-white">
                                  Our firm has been growing each year since inception across all key
                                  metrics, including employees (25 new consulting team members in
                                  19’), geographies (5th office in Boston/Cambridge this Spring and
                                  a 6th office in Philadelphia later in 19’), and clients/revenues.
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </section>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="bg-appcolor">
              <div className="d-none d-lg-block empty-space" />
              {/*** this is to add extra space below map 
              animation so that it won't position:sticky 
              after last slide ***/}
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default OurStory;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "our-story" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rowDescriptionOne
        rowDescriptionTwo
        rowDescriptionThree
        rowDescriptionFour
        ourStory {
          number
          title
        }
        growthDescription
      }
    }
  }
`;

import React, { PureComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import Bluekey from '../../../static/assets/blue_key.svg';

class DescriptionWithKey extends PureComponent {
  render() {
    const { description } = this.props;
    return (
      <Row>
        <Col xs={12} className="description_column opacity0">
          <div className="position-relative line line-horizontal">
            <Bluekey />
          </div>
          <p className="story_description">{description}</p>
        </Col>
      </Row>
    );
  }
}

export default DescriptionWithKey;
